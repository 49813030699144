
@font-face {
    font-family: 'Eina03';
    src: url('./fonts/Eina03-Regular.ttf');
}

@font-face {
    font-family: 'Eina03Bold';
    src: url('./fonts/Eina03-Bold.ttf');
}
@font-face {
    font-family: 'Eina03SemiBold';
    src: url('./fonts/Eina03-SemiBold.ttf');
}

html,
body {
    position: relative;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 0;
}

#home-services-app { 
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: white;
}

.swiper-slide img, .swiper-slide svg  {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}
h1, h2 {
    font-family: 'Eina03';
    font-style: normal;
    font-weight: bold;
}
#preview {
    display: inline-block;
    overflow: auto;
    overflow-y: hidden;
    max-width: 100%;
    margin: 0 0 1em;
    white-space: nowrap;
}

#loader-container{
    position:relative;
    height:100%;
    z-index: -1;
}

.fixed-main-loader{
    left: 50% !important;
    margin-left:-100px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -100px;
    width: 45px;
    z-index: 9000 !important;
}

.fixed-main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: 200px;
    height:200px;
}
.fixed-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

#home-loader {
    -webkit-animation: scale-fade-in 3s linear;
            animation: scale-fade-in 3s linear;
    height: 100%;
    -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
            transform-origin: center center;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

#poplar {
    opacity: 0;
    -webkit-animation: 2s fade-in 2s linear;
            animation: 2s fade-in 2s linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes scale-fade-in {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        -webkit-transform: scale(10);
        transform: scale(20);
        z-index: -1;
    }
}

@keyframes scale-fade-in {
    from {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    to {
        -webkit-transform: scale(10);
        transform: scale(20);
        z-index: -1;
    }
}


.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke: #FF7300;
    fill: #FF7300;
    stroke-width: 0.5;
    stroke-miterlimit: 2.6131;
}


.topNav {
    font-family: 'Eina03Bold';
    background-color: #FF7300;
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;
}

#poplar-logo-header {
    width: 50%;
    height: 50%;
}

@media (min-width:320px)  { 
    /* smartphones, iPhone, portrait 480x320 phones */
    #poplar-logo-loader {
        margin-left: 20px;
        margin-right: 20px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 300px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }
    .content-container {
        margin-top: 10px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width:481px)  { 
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
    #poplar-logo-loader {
        margin-left: 10px;
        margin-right: 10px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 400px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }
    .content-container {
        margin-top: 10px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (min-width:641px)  { 
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    #poplar-logo-loader {
        margin-left: 25px;
        margin-right: 25px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 100px;
        margin-right: 100px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 500px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }
    .content-container {
        margin-top: 10px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media (min-width:961px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    #poplar-logo-loader {
        margin-left: 200px;
        margin-right: 200px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 200px;
        margin-right: 200px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 500px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }

    .content-container {
        margin-top: 10px;
    }
    .swiper-border-container {
        border: #FF7300 dashed 2px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 200px;
        padding-right: 200px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 200px;
        padding-right: 200px;
    }
}

@media (min-width:1025px) { 
    /* big landscape tablets, laptops, and desktops */ 
    #poplar-logo-loader {
        margin-left: 200px;
        margin-right: 200px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 400px;
        margin-right: 400px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 700px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }
    .content-container {
        margin-top: 10px;
    }
    .swiper-border-container {
        border: #FF7300 dashed 2px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 200px;
        padding-right: 200px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 200px;
        padding-right: 200px;
    }
}

@media (min-width:1281px) { 
    /* hi-res laptops and desktops */ 
    #poplar-logo-loader {
        margin-left: 200px;
        margin-right: 200px;
    }

    .sali-container {
        margin-top: 5px;
        margin-left: 500px;
        margin-right: 500px;
    }

    #viewSwiper {
        width: 100%;
        height: 80%;
        margin-top: 85px;
    }

    #uploadSwiper {
        width: 100%;
        height: 600px;
    }

    body {
        overflow-y: visible;
    }

    #status {
        margin-top: 85px;
    }
    .content-container {
        margin-top: 10px;
    }
    .swiper-border-container {
        border: #FF7300 dashed 2px;
    }
    #form-file-upload {
        height: 250px;
        width: 100%;
        padding-left: 200px;
        padding-right: 200px;
        text-align: center;
        position: relative;
    }
    #file-list {
        padding-left: 200px;
        padding-right: 200px;
    }
}

.action-border-container {
    margin-top: 20px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
    background-color: #FF7300 !important;
    border-color: #FF7300;
    outline-color: red;
}

.btn, .btn-secondary {
    font-family: 'Eina03SemiBold';
}

#maintenance-label {
    font-family: 'Eina03SemiBold';
    font-size: 20px;
    color: #FF7300;
}

#remove-button {
    border-radius: 50%;
    padding: 0.5em;
    width: 30px;
    height: 30px;
    border: 2px solid blue;
    color: blue;
    position: relative;
    z-index: 10000;
}

#remove-button:hover {
    border: 2px solid blue;
    background-color: red;
    color: #ffffff;
}

#remove-button::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: blue;
    width: 2px;
    left: 12px;
    top: 5px;
    bottom: 5px;
    transform: rotate(45deg);
}

#remove-button::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: blue;
    height: 2px;
    top:12px;
    left: 5px;
    right: 5px;
    transform: rotate(45deg);
}
  
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

#homeservices-text {
    fill: rgb(255, 255, 255);
    font-family: 'Eina03Bold';
    font-size: 58px;
    white-space: pre;
}

#by-poplarhomes-text {
    fill: rgb(255, 255, 255);
    font-family: 'Eina03Bold';
    font-size: 24px;
    white-space: pre;
}

.upload-document-title {
    font-family: 'Eina03Bold';
    font-size: 24px;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    font-family: 'Eina03Bold';
}

#label-file-upload.drag-active {
    background-color: #ffffff;  
}

#s3-save-button {
    width: 100%;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Eina03Bold';
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

.upload-button-x {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Eina03Bold';
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
#file-list {
    list-style: none;
    background-color: transparent;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-family: 'Eina03Bold';
    font-size: 12px;
}
tr:nth-child(even) {
    background-color: #dddddd;
}

.file-list-table {
    width: 100%;
}
.font-eina {
    font-family: 'Eina03Bold';
}
.orange-progress-bar .progress-bar {
    background-color: #FF7300;
}